// Adapted from: https://github.com/simonyiszk/schdesign-web/blob/1ee8aa87b702a1ef0473e8a553207c3a2355d128/src/components/Gallery.tsx#L98
import Img, { FluidObject } from "gatsby-image";
import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Box } from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";
// import carouselFormatters from "../utils/carouselFormatters";
import { chunk, sum } from "lodash";
import styled from "@emotion/styled";
import theme from "../../theme";
import { ImageGalleryDetails } from "../../types";
import { generateCaptionInnerHtmlString } from "../../util";

export interface ImageGalleryProps extends ImageGalleryDetails {
  itemsPerRow: number[];
}

const StyledImageBox = styled(Box)`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  transition: filter ${theme.animation.speed.normal};
  transform: scale(0.98, 0.98);

  :hover {
    filter: brightness(60%);
  }
`;

// Note that there is style in GlobalCSS that constrains the open image to 100vh
// src/components/GlobalCss.tsx
const ImageGallery: React.FC<ImageGalleryProps> = (props) => {
  const { images, itemsPerRow: itemsPerRowByBreakpoints } = props;
  const aspectRatios = images.map((image) => image.data.aspectRatio);

  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    (itemsPerRow) =>
      chunk(aspectRatios, itemsPerRow).map((rowAspectRatios) => {
        if (rowAspectRatios.length < itemsPerRow) {
          const pad = new Array(itemsPerRow - rowAspectRatios.length).fill(
            rowAspectRatios[0]
          );
          return sum([...rowAspectRatios, ...pad]);
        } else {
          return sum(rowAspectRatios);
        }
      })
  );

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalCurrentIndex, setModalCurrentIndex] = React.useState(0);

  const closeModal = () => setModalIsOpen(false);
  const openModal = (imageIndex: number) => {
    setModalCurrentIndex(imageIndex);
    setModalIsOpen(true);
  };

  const galleryViewerFooterFontSize = useBreakpointValue([
    theme.fontSizes.sm,
    theme.fontSizes.md,
    theme.fontSizes.xl
  ]);

  return (
    <Box>
      {images.map((image, i) => (
        <StyledImageBox
          key={`gallery-idx-${i}`}
          onClick={(e) => {
            e.preventDefault();
            openModal(i);
          }}
          title={image.title}
          // https://chakra-ui.com/responsive-styles
          width={rowAspectRatioSumsByBreakpoints.map(
            (rowAspectRatioSums, j) => {
              const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j]);
              const rowAspectRatioSum = rowAspectRatioSums[rowIndex];
              return `${(image.data.aspectRatio / rowAspectRatioSum) * 100}%`;
            }
          )}
        >
          <Img fluid={image.data as FluidObject} />
        </StyledImageBox>
      ))}
      <ModalGateway>
        {modalIsOpen && (
          <Modal
            onClose={closeModal}
            styles={{
              blanket: (style) => ({
                ...style,
                backgroundColor: "rgba(0, 0, 0, 0.9)"
              })
            }}
          >
            <Carousel
              // Enable to show left/right buttons (not typed
              // for some reason)
              {...{ showNavigationOnTouchDevice: true }}
              hideControlsWhenIdle={3000}
              views={images.map((img) => {
                // Use _either_ the overall gallery credit, or this one image's credit
                const chosenCredit = img.credit ?? props.credit;
                const captionHtml = generateCaptionInnerHtmlString(
                  img.title,
                  chosenCredit
                );

                return {
                  source: img.data.src,
                  caption: (
                    <p dangerouslySetInnerHTML={{ __html: captionHtml }} />
                  )
                };
              })}
              currentIndex={modalCurrentIndex}
              styles={{
                footer: (style) => ({
                  ...style,
                  opacity: 1,
                  fontSize: galleryViewerFooterFontSize
                }),
                header: (style) => ({ ...style, opacity: 1 }),
                navigation: (style) => ({ ...style, opacity: 1 })
              }}
            />
          </Modal>
        )}
      </ModalGateway>
    </Box>
  );
};

export default ImageGallery;
