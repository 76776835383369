import { graphql, PageProps } from "gatsby";
import React from "react";
import Galleries from "../components/gallery/ImageGalleries";
import Gallery from "../components/gallery/ImageGallery";
import Page from "../components/Page";
import { PageTitle } from "../components/PageTitle";
import SEO from "../components/SEO";
import AnimatingTabs from "../components/Tabs";
import { ImageGalleryDetails, Image } from "../types";
import { innerHtmlFromPrismicRaw } from "../util";

export const galleriesQuery = graphql`
  {
    prismicProductionImageGallery {
      data {
        body {
          ... on PrismicProductionImageGalleryBodyGallery {
            id
            primary {
              image_credit_link
              image_credit_name
              gallery_name {
                raw
              }
            }
            items {
              gallery_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicHeadshotImageGallery {
      data {
        headshot_image {
          headshot {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          image_credit_name
          image_title
          image_credit_link {
            url
          }
        }
      }
    }
  }
`;

enum GalleryTabs {
  PRODUCTION,
  HEADSHOTS
}

interface GalleryTabProps {
  // onClick: (ev: MouseEvent) => void;
  // id: string;
  // isSelected: boolean;
  tab: GalleryTabs;
  chosenTab: GalleryTabs;
  children: React.ReactNode;
}

const GalleryPage: React.FC<PageProps<any>> = ({ data }) => {
  const productionGalleries: ImageGalleryDetails[] = data.prismicProductionImageGallery.data.body.map(
    (gal: any) => ({
      credit: {
        title: gal.primary.image_credit_name,
        link: gal.primary.image_credit_link
      },
      title: innerHtmlFromPrismicRaw(gal.primary.gallery_name.raw),
      images: gal.items.map((it: any) => ({
        data: it.gallery_image.localFile.childImageSharp.fluid,
        title: innerHtmlFromPrismicRaw(gal.primary.gallery_name.raw)
      }))
    })
  );

  const headshotsGallery: Image[] = data.prismicHeadshotImageGallery.data.headshot_image.map(
    (hs: any) => ({
      credit: {
        title: hs.image_credit_name,
        link: hs.image_credit_link.url
      },
      title: hs.image_title,
      data: hs.headshot.localFile.childImageSharp.fluid
    })
  );

  return (
    <Page>
      <SEO title="Gallery" />
      <PageTitle>Gallery</PageTitle>
      <AnimatingTabs
        tabTitles={["Production", "Headshots"]}
        tabContents={[
          // Production Shots
          <Galleries
            key={"gallery-production"}
            galleries={productionGalleries}
            // 2 at smallest breakpoint, then 3
            itemsPerRow={[2, 3]}
          />,
          <Gallery
            title="Headshots"
            // Unused as each headshot image supplies its own
            credit={{ title: "", link: "" }}
            key={"gallery-headshots"}
            images={headshotsGallery}
            itemsPerRow={[2, 3]}
          />
        ]}
      />
    </Page>
  );
};

export default GalleryPage;
