import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import theme from "../../theme";
import { ImageGalleryDetails } from "../../types";
import { Subheading } from "../Subheading";
import ImageGallery from "./ImageGallery";

const StyledAccordionPanel = styled(AccordionPanel)`
  padding: 0;
`;

const StyledAccordionButton = styled(AccordionButton)<{ open: boolean }>`
  background-color: ${(props) =>
    props.open ? theme.colors.palette[100] : theme.colors.palette[200]};
  cursor: pointer;
  transition: background-color ${theme.animation.speed.normal};
  min-height: 70px;

  :hover,
  :active,
  :focus {
    background-color: ${theme.colors.palette[100]};
  }
`;

// An array of <Gallery> wrapped in an Accordion
const Galleries: React.FC<{
  galleries: ImageGalleryDetails[];
  itemsPerRow: number[];
}> = (props) => {
  const [openIndex, setOpenIndex] = React.useState(0);

  return (
    <Accordion
      allowMultiple={false}
      defaultIndex={0}
      allowToggle={true}
      onChange={(next) => next && setOpenIndex(next as number)}
    >
      {props.galleries.map((gal, idx) => (
        <AccordionItem key={`production-gallery-${idx}`}>
          <StyledAccordionButton open={openIndex === idx}>
            <Box flex="1" textAlign="left">
              <Subheading innerHTML={gal.title} />
            </Box>
            <AccordionIcon
              color={theme.colors.black}
              width={theme.fontSizes["4xl"]}
              height={theme.fontSizes["4xl"]}
            />
          </StyledAccordionButton>
          <StyledAccordionPanel>
            <ImageGallery {...gal} itemsPerRow={props.itemsPerRow} />
          </StyledAccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default Galleries;
